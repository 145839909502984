<div class="LoginArea LoginReverse">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/set-password.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <h1>Set Password</h1>
            <h6>Please create new <br>password.</h6>
            <form [formGroup]="formGroup">
                <div class="form-group">
                    <label for="">Password</label>
                    <input (change)="onChangePass()" formControlName="newPassword" #y type="password" class="form-control" placeholder="Enter Password">
                    <span class="Icon Cross" (click)="y.type=y.type=='password'?'text':'password'"><img src="assets/images/eye.png"></span>
                </div>
                <div class="text-danger"
                    *ngIf="(formGroup.get('newPassword')?.hasError('required') && (formGroup.get('newPassword')?.dirty))|| (formInvalid && !this.formGroup.value.newPassword)">
                    *Password is required
                </div>
                <div class="text-danger"
                    *ngIf="(!formGroup.get('newPassword')?.hasError('pattern') && formGroup.get('newPassword')?.hasError('minlength') && (formGroup.get('newPassword')?.dirty))">
                    *Password must be of minimum 6 characters length
                </div>
                <div class="text-danger"
                    *ngIf="(!formGroup.get('newPassword')?.hasError('pattern') && formGroup.get('newPassword')?.hasError('maxLength') && (formGroup.get('newPassword')?.dirty))">
                    *Password can be max 16 characters length
                </div>

                <div class="form-group">
                    <label for="">Re-Enter Password</label>
                    <input (change)="onChangePass()" formControlName="conPassword" #x type="password" class="form-control" placeholder="Enter Password">
                    <span class="Icon Cross" (click)="x.type=x.type=='password'?'text':'password'"><img src="assets/images/eye.png"></span>
                </div>
                <div class="text-danger"
                    *ngIf="(formGroup.get('conPassword')?.hasError('required') && (formGroup.get('conPassword')?.dirty))|| (formInvalid && !this.formGroup.value.conPassword)">
                    *Re-Enter Password is required
                </div>
                <div class="text-danger" *ngIf="confirmPass">
                    *Password and confirm password must be same
                </div> 

                <button (click)="onSave()">Next <img src="assets/images/next-arrow.svg" /></button>
            </form>
        </aside>
    </div>
</div>