<app-header></app-header>
<app-sidenav></app-sidenav>

<div class="Main-wrapper">
    <div class="Wrapper">

        <div class="VaccinationArea">
     
            <div class="VaccinationHead">
            
            </div>

            <div class="VaccinationBox">
              
                    <h4><img src="assets/images/Vitals-1.svg" alt=""> Medical Chart</h4>
                   <h3>Download Chart Reports</h3>
                   <p>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</p>
                   <a href="javascript:void(0);"> <img src="assets/images/download.svg" alt=""> Download Chart Reports</a>
               
             
             
            </div> 


        </div> 
    </div>
</div>     


  <!-- <div class="modal fade" id="chart" role="dialog">
    <div class="modal-dialog">
    
 
      <div class="modal-content" style="position: relative;">
          <button type="button" class="close closed" data-dismiss="modal">&times;</button>
        <div class="modal-body" style="text-align: center;">
            <div class="VaccinationBox" style="max-width: 100%;padding: 40px;text-align: center;">
                 <h4><img src="assets/images/Vitals-1.svg" alt=""> Medical Chart</h4>
                <h3>View Vaccination Report.</h3>
                <p>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</p>
                <a href="javascript:void(0);"> <img src="assets/images/download.svg" alt=""> Download Vital Reports</a>
            
          
            </div>
        </div>


      </div>
      
    </div>
  </div> -->