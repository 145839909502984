<div class="LoginArea LoginReverse">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/Allergies.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>

            <a [routerLink]="['/register-medication']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
            <h5 class="Step">Step 5 of 7 </h5> 
            <form action=""> 
                <div class="Medication">
                    <article>
                        <figure><img src="assets/images/Allergies-1.svg" /></figure>
                        <h6>Allergies</h6>
                        <p>Select which type of allergies you <br>
                            have ?</p>
                    </article>
                    <a class="MedicationOpen" href="javascript:void(0);" data-toggle="modal" data-target="#MedicationModal"> Select  Allergies </a> 
                    
                    <div class="MedicationList">
                        <span>
                            Adderall 
                            <a href="javascript:void(0);">&times;</a>
                        </span>
                        <span>
                            Cipro 
                            <a href="javascript:void(0);">&times;</a>
                        </span>
                        <span>
                            Cymbalta 
                            <a href="javascript:void(0);">&times;</a>
                        </span>
                        <span>
                            Flexeril 
                            <a href="javascript:void(0);">&times;</a>
                        </span> 
                    </div>
                    
                </div>
                 
                <div class="Medication">
                    <article>
                        <figure><img src="assets/images/Allergies-2.svg" /></figure>
                        <h6>Surgeries</h6>
                        <p>Have you ever been done with any <br>
                            of the surgeries?</p>
                    </article>
                    <a class="MedicationOpen" href="javascript:void(0);" data-toggle="modal" data-target="#MedicationModal"> Select  Surgeries </a> 
                
                    <div class="MedicationList">
                        <span>
                            Adderall 
                            <a href="javascript:void(0);">&times;</a>
                        </span>
                        <span>
                            Cipro 
                            <a href="javascript:void(0);">&times;</a>
                        </span>
                        <span>
                            Cymbalta 
                            <a href="javascript:void(0);">&times;</a>
                        </span>
                        <span>
                            Flexeril 
                            <a href="javascript:void(0);">&times;</a>
                        </span> 
                    </div> 
                </div>
                <button [routerLink]="['/register-family-member']">Next <img src="assets/images/next-arrow.svg" /></button>
            </form>
        </aside>
    </div>
</div>


<div class="ModalBox">
    <div class="modal FullModal" id="MedicationModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="LoginArea">
                    <div class="LoginLeft">
                        <aside>
                            <figure><img src="assets/images/Medication.svg" /></figure>
                            <h3>Lorem Ipsum is simply</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                        </aside>
                    </div>
                
                    <div class="LoginRight MedicationSelect">
                        <aside>  
                            <form action=""> 

                                <div class="Search">
                                    <span><img src="assets/images/Search.svg" /></span>
                                    <input type="text" class="form-control" placeholder="Search and Select Medication">
                                </div>            
                                <div class="SelectBox">
                                    <label class="CheckBox">Adderall
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Celexa
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Cipro
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Cymbalta
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Flexeril
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Hydrocodone
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Lexapro
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Lisinopril
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Mobic
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label> 
                                    <label class="CheckBox">Adderall
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Celexa
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Cipro
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Cymbalta
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Flexeril
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Hydrocodone
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Lexapro
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Lisinopril
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Mobic
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label> 
                                    <label class="CheckBox">Adderall
                                        <input type="checkbox" checked="checked">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Celexa
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Cipro
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Cymbalta
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Flexeril
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Hydrocodone
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Lexapro
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Lisinopril
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label>
                                        
                                    <label class="CheckBox">Mobic
                                        <input type="checkbox">
                                        <span class="Checkmark"></span>
                                    </label> 

                                </div>
                                <button id="Medicateopen" data-dismiss="modal">Next <img src="assets/images/next-arrow.svg" /> </button>
                            </form>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>