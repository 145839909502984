import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-lab',
  templateUrl: './history-lab.component.html',
  styleUrls: ['./history-lab.component.css']
})
export class HistoryLabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
