<app-header></app-header>
<app-sidenav></app-sidenav>

<div class="Main-wrapper">
    <div class="Wrapper">

        <div class="BreadcumBox" *ngIf="!editLocation">
            <h5>Manage My Account</h5>
            <ul>
                <li><a href="javascript:void(0);"><img src="assets/images/home.svg"></a></li>
                <li><a href="javascript:void(0);">Home </a></li>
                <li><a href="javascript:void(0);">Manage My Account</a></li>
            </ul>
        </div>

        <div class="ManageAccountBox" *ngIf="!editLocation">
            <div class="ProfileBox">
                <figure>
                    <span class="ProfileIcon">
                        <img src="{{userData?.profilePic}}">
                        <span class="Camera">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                            <input (change)="onFileChange($event)" type="file">
                        </span>
                    </span>
                    <a>Edit Profile</a>
                </figure>
                <figcaption>
                    <h3>{{userData?.firstName}} {{userData?.lastName}}</h3>
                    <p>Cordato member since {{getdatemonth(userData?.createdAt)}}</p>
                </figcaption>
            </div>

            <div class="CommonArea">
                <div class="CommonHead">
                    <figure>
                        <img src="assets/images/profile-1.svg">
                    </figure>
                    <h5>Basic Profile</h5>
                </div>

                <div class="CommonBody">
                    <form class="CommonForm" [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">First Name</label>
                                    <input formControlName="firstName" type="text" class="form-control"
                                        placeholder="First Name" value="zeeshan">
                                </div>
                            </div>
                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Last Name</label>
                                    <input formControlName="lastName" type="text" class="form-control"
                                        placeholder="Last Name">
                                </div>
                            </div>
                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input formControlName="email" type="text" class="form-control"
                                        placeholder="Enter Email Address">
                                </div>
                            </div>
                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Phone Number</label>
                                    <input formControlName="phoneNumber" type="text" class="form-control"
                                        placeholder="+91 xxxxxxxxxx">
                                    <span class="Icon"><a href="javascript:void(0);" data-toggle="modal"
                                            data-target="#NumberModal">Edit</a></span>
                                </div>
                            </div>

                            <div class="col-sm-10">
                                <div class="form-group">
                                    <label for="">Address</label>
                                    <input formControlName="address" type="text" class="form-control"
                                        placeholder="Enter Address">
                                    <span class="Icon"><a href="javascript:void(0);"
                                            (click)="onEditLocation()">Edit</a></span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="CommonArea">
                <div class="CommonHead">
                    <figure>
                        <img src="assets/images/profile-2.svg">
                    </figure>
                    <h5>Change Password</h5>
                </div>

                <div class="CommonBody">
                    <form [formGroup]="formGroup2" class="CommonForm">
                        <div class="row">
                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Old Password</label>
                                    <input #z formControlName="oldPassword" type="password" class="form-control"
                                        placeholder="Enter Password">
                                    <span class="Icon Cross" (click)="z.type=z.type=='password'?'text':'password'">
                                        <img src="assets/images/eye.svg">
                                    </span>
                                </div>
                                <div class="text-danger"
                                    *ngIf="(formGroup2.get('oldPassword')?.hasError('required') && (formGroup2.get('oldPassword')?.dirty))|| (formInvalid2 && !this.formGroup2.value.oldPassword)">
                                    *Old Password is required
                                </div>
                            </div>

                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Password</label>
                                    <input #y (change)="onChangePass()" formControlName="newPassword" type="password"
                                        class="form-control" placeholder="Enter Password">
                                    <span class="Icon Cross" (click)="y.type=y.type=='password'?'text':'password'"><img
                                            src="assets/images/eye.svg"></span>
                                </div>
                                <div class="text-danger"
                                    *ngIf="(formGroup2.get('newPassword')?.hasError('required') && (formGroup2.get('newPassword')?.dirty))|| (formInvalid2 && !this.formGroup2.value.newPassword)">
                                    *Password is required
                                </div>
                            </div>

                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Re-Enter Password</label>
                                    <input #x (change)="onChangePass()" formControlName="conPassword" type="password"
                                        class="form-control" placeholder="Re-Enter Password">
                                    <span class="Icon Cross" (click)="x.type=x.type=='password'?'text':'password'"><img
                                            src="assets/images/eye.svg"></span>
                                </div>

                                <div class="text-danger"
                                    *ngIf="(!formGroup2.get('oldPassword')?.hasError('pattern') && formGroup2.get('oldPassword')?.hasError('minlength') && (formGroup2.get('oldPassword')?.dirty))">
                                    *Old Password must be of minimum 6 characters length
                                </div>
                                <div class="text-danger"
                                    *ngIf="(!formGroup2.get('oldPassword')?.hasError('pattern') && formGroup2.get('oldPassword')?.hasError('maxlength') && (formGroup2.get('oldPassword')?.dirty))">
                                    *Old Password must be of max 16 characters length
                                </div>
                            </div>

                            <!-- <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Password</label>
                                    <input #y (change)="onChangePass()" formControlName="newPassword" type="password" class="form-control" placeholder="Enter Password">
                                    <span class="Icon Cross" (click)="y.type=y.type=='password'?'text':'password'"><img src="assets/images/eye.svg"></span>
                                </div>
                                <div class="text-danger"
                                    *ngIf="(formGroup2.get('conPassword')?.hasError('required') && (formGroup2.get('conPassword')?.dirty))|| (formInvalid2 && !this.formGroup2.value.conPassword)">
                                    *Re-Enter Password is required
                                </div>
                                <div class="text-danger" *ngIf="(formInvalid2 && confirmPass)">
                                    *Password and confirm password must be same</div>

                                <div class="text-danger"
                                    *ngIf="(!formGroup2.get('newPassword')?.hasError('pattern') && formGroup2.get('newPassword')?.hasError('minlength') && (formGroup2.get('newPassword')?.dirty))">
                                    *Password must be of minimum 6 characters length
                                </div>
                                <div class="text-danger"
                                    *ngIf="(!formGroup2.get('newPassword')?.hasError('pattern') && formGroup2.get('newPassword')?.hasError('maxlength') && (formGroup2.get('newPassword')?.dirty))">
                                    *Password must be of max 16 characters length
                                </div>
                            </div>

                            <div class="col-sm-5 ">
                                <div class="form-group">
                                    <label for="">Re-Enter Password</label>
                                    <input #x (change)="onChangePass()" formControlName="conPassword" type="password" class="form-control" placeholder="Re-Enter Password">
                                    <span class="Icon Cross" (click)=" x.type=x.type=='password'?'text':'password'"><img src="assets/images/eye.svg"></span>
                                </div>
                            </div> -->
                        </div>
                    </form>
                </div>
            </div>

            <div class="CommonArea">
                <div class="CommonHead">
                    <figure>
                        <img src="assets/images/profile-2.svg">
                    </figure>
                    <h5>Check In Questions Management</h5>
                    <a class="history" href="javascript:void(0)" data-toggle="modal" data-target="#QuestionsAddModal">Add Questions</a>
                </div>

                <div class="CommonBody">
                    <div class="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Check In Measurement</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of questionList; let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{x.question}}</td>
                                    <td>
                                        <div class="Action">
                                            <a href="javascript:void(0);" class="Edit" (click)="questionEditId(x)">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="Delete" (click)="questionDeletId(x)">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="CommonArea">
                <div class="CommonHead">
                    <h5>&nbsp;</h5>
                    <a class="history" [routerLink]="['/history-lab']">Lab History</a>
                </div>

                <div class="CommonBody">
                    <div class="row">
                        <div class="col-sm-6" *ngFor="let para of parameterList">
                            <div class="Medication SurgeryMedication">
                                <article>
                                    <figure><img src="{{para.icon}}" /></figure>
                                    <h6>{{para.name}}</h6>
                                    <p>{{para.description}}</p>
                                </article>
                                <a class="MedicationOpen" href="javascript:void(0);" (click)="onSelectParameter(para)">
                                    Select {{para.name}}</a>

                                <div class="MedicationList" style="display: block;"
                                    *ngIf="para.selectedSubParameters && para.selectedSubParameters.subparametersname">
                                    <span *ngFor="let select of para.selectedSubParameters.subparametersname">
                                        {{select.name}}
                                        <a href="javascript:void(0);"
                                            (click)="ondelete(select._id,para._id)">&times;</a>
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="Medication SurgeryMedication">
                                <h5>Recent Surgery ?</h5>
                                <input type="text" class="form-control" placeholder="What was the surgery for ?">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="CommonButon">
                <button class="Button" (click)="onSubmitPass()">Save</button>
            </div>

            <div class="CommonArea">
                <div class="CommonHead">
                    <figure>
                        <img src="assets/images/profile-1.svg">
                    </figure>
                    <h5>Family History</h5>

                    <a class="history" [queryParams]="{ mang:'Yes'}" href="javascript:void(0)"
                        [routerLink]="['/register-family-member-add']">Add Member</a>
                </div>

                <div class="CommonBody padding_none">
                    <div class="AppointmentDeatilsds">
                        <div class="TableList FamilyTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Relation</th>
                                        <th>Year of Birth</th>
                                        <th>Conditions</th>
                                        <th>Death Year (If)</th>
                                        <th>Death Reason (If)</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let fam of familyList">
                                        <td>{{fam.name}}</td>
                                        <td>{{fam.birthYear}}</td>
                                        <td>{{fam.relation}}</td>
                                        <td>
                                            <div
                                                *ngIf="fam && fam['subparametersname'] && fam['subparametersname'].length ">
                                                <span
                                                    *ngFor="let para of fam['subparametersname']">{{para.name}},</span>
                                            </div>
                                        </td>
                                        <td>{{fam.deathYear}}</td>
                                        <td>{{fam.causesOfDeath}}</td>
                                        <td>
                                            <a [queryParams]="{ familyId:fam._id,mang:'Yes'}" class="Edit"
                                                [routerLink]="['/register-family-member-edit']">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5"
                                                    viewBox="0 0 20 20" fill="currentColor">
                                                    <path
                                                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                </svg>
                                            </a>

                                            <a class="Delete" href="javascript:void(0);" data-toggle="modal"
                                                (click)="onDeleteFunction(fam)">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                    viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ManageAccountLocation" *ngIf="editLocation">
            <form [formGroup]="formGroup3">
                <div class="AccountLocationForm">
                    <div class="AccountLocationLeft">
                        <agm-map [latitude]="currentLat" [longitude]="currentLong" [zoom]="zoom"
                            [disableDefaultUI]="false" [zoomControl]="false" (mapClick)="mapClicked($event)">
                            <agm-marker [latitude]="currentLat" [longitude]="currentLong"></agm-marker>
                        </agm-map>

                        <div class="Search">
                            <span><img src="assets/images/Search.svg" /></span>
                            <input formControlName="searchAddress" id="sourcePlace"
                                (focus)="focusFunction('sourcePlace')" type="text" class="form-control"
                                placeholder="Sector 63, Noida">
                        </div>
                    </div>

                    <div class="text-danger"
                        *ngIf="(formGroup3.get('searchAddress')?.hasError('required') && (formGroup3.get('searchAddress')?.dirty))|| (formInvalid && !this.formGroup3.value.searchAddress)">
                        *Address is required
                    </div>

                    <div class="AccountLocationRight">
                        <div class="form-group">
                            <label for="">Address</label>
                            <input formControlName="address" type="text" class="form-control"
                                placeholder="Enter Address" value="Sector 63, Noida">
                        </div>

                        <div class="text-danger"
                            *ngIf="(formGroup3.get('address')?.hasError('required') && (formGroup3.get('address')?.dirty))|| (formInvalid && !this.formGroup3.value.address)">
                            *Address is required
                        </div>

                        <div class="form-group">
                            <label for="">Building/Apartment No.</label>
                            <input formControlName="building" type="text" class="form-control"
                                placeholder="Enter Building No." value="H-146/147">
                        </div>
                        <div class="text-danger"
                            *ngIf="(formGroup3.get('building')?.hasError('required') && (formGroup3.get('building')?.dirty))|| (formInvalid && !this.formGroup3.value.building)">
                            *Building is required
                        </div>

                        <button (click)="onSetLoction()">Set Location <img
                                src="assets/images/next-arrow.svg" /></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>





<div class="ModalBox">
    <div class="modal" id="MedicationModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="MedicationBox">
                    <div class="MedicationSelect">
                        <aside>
                            <form [formGroup]="otpForm" action="">
                                <div class="Search">
                                    <span><img src="assets/images/Search.svg" /></span>
                                    <input formControlName="searchText" (input)="onSearch()" type="text"
                                        class="form-control" placeholder="Search and Select {{selectedPara?.name}}">
                                </div>
                                <div class="SelectBox">
                                    <label class="CheckBox" *ngFor="let item of subParameterList">{{item.name}}
                                        <input type="checkbox" (input)="onInputCheckChage(item)"
                                            [checked]="item.isSelected">
                                        <span class="Checkmark"></span>
                                    </label>

                                </div>
                                <button id="Medicateopen" class="Button" (click)="onNextSubParameters()">Next </button>
                            </form>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="LocationModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="LoginArea LocationModal">

                    <div class="LoginRight">
                        <form [formGroup]="formGroup3" class="Map">
                            <img src="assets/images/map.png">
                            <div class="Search">
                                <span><img src="assets/images/Search.svg" /></span>
                                <input formControlName="searchAddress" id="sourcePlace"
                                    (focus)="focusFunction('sourcePlace')" type="text" class="form-control"
                                    placeholder="Sector 63, Noida">
                            </div>
                            <div class="text-danger"
                                *ngIf="(formGroup3.get('searchAddress')?.hasError('required') && (formGroup3.get('searchAddress')?.dirty))|| (formInvalid && !this.formGroup3.value.searchAddress)">
                                *Address is required
                            </div>
                        </form>
                        <div class="Form">
                            <aside>
                                <form action="" [formGroup]="formGroup3">
                                    <div class="form-group">
                                        <label for="">Address</label>
                                        <input formControlName="address" type="text" class="form-control"
                                            placeholder="Enter Address" value="Sector 63, Noida">
                                    </div>
                                    <div class="text-danger"
                                        *ngIf="(formGroup3.get('address')?.hasError('required') && (formGroup3.get('address')?.dirty))|| (formInvalid && !this.formGroup3.value.address)">
                                        *Address is required
                                    </div>
                                    <div class="form-group">
                                        <label for="">Building/Apartment No.</label>
                                        <input formControlName="building" type="text" class="form-control"
                                            placeholder="Enter Building No." value="H-146/147">
                                    </div>
                                    <div class="text-danger"
                                        *ngIf="(formGroup3.get('building')?.hasError('required') && (formGroup3.get('building')?.dirty))|| (formInvalid && !this.formGroup3.value.building)">
                                        *Building is required
                                    </div>

                                </form>
                                <button (click)="onSetLoction()">Set Location <img
                                        src="assets/images/next-arrow.svg" /></button>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="NumberModal">
        <div class="modal-dialog">
            <div class="modal-content">

                <div class="Category">
                    <h3>Update Phone Number</h3>
                    <form action="" [formGroup]="formGroup1">
                        <div class="form-group">
                            <label for="">Phone Number</label>


                            <ngx-intl-tel-input formControlName="phoneNumber" [enableAutoCountrySelect]="false"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [selectedCountryISO]="CountryISO[selectedCountry]"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [phoneValidation]="false" [separateDialCode]="true">
                            </ngx-intl-tel-input>


                            <!-- <ngx-intl-tel-input  [selectedCountryISO]="CountryISO[selectedCountry]" formControlName="phoneNumber" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [phoneValidation]="true" [separateDialCode]="true"> </ngx-intl-tel-input> -->

                            <!-- <input formControlName="phoneNumber" type="text" class="form-control"
                                placeholder="Enter Address"> -->
                        </div>
                        <div class="text-danger" *ngIf="phoneInvalid">
                            *Phone Number is required
                        </div>
                        <div class="text-danger" *ngIf="formInvalid1">
                            *Please enter valid Phone Number
                        </div>
                        <button class="Button" (click)="AddressStep()">Update</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="DeathReasonModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="Decline Reason">
                        <a href="JavaScript:Void(0);" data-dismiss="modal" class="CloseModal">×</a>
                        <h3>Conditions</h3>
                        <p>
                            High BP, Anxity, High BP, Anxity, High BP, Anxity, High BP, Anxity, High BP, Anxity, High
                            BP, Anxity, High BP, Anxity, High BP, Anxity
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="DeleteModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="Decline">
                        <a href="JavaScript:Void(0);" data-dismiss="modal" class="CloseModal">×</a>
                        <h3>Delete</h3>
                        <p>Are you sure you want to delete this Family Member?</p>
                        <h4>
                            <a href="JavaScript:Void(0);" data-dismiss="modal">No</a>
                            <a href="JavaScript:Void(0);" (click)="onYes()">Yes</a>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="QuestionsAddModal">
        <div class="modal-dialog"> 
            <div class="modal-content">
                <div class="Category">
                    <a href="javascript:void(0)" class="CloseModal">&times;</a>
                    <h3>Questions</h3>
                    <form [formGroup]="questionsAdd">
                        <div class="form-group">
                            <label>Questions</label>
                            <input type="text" class="form-control" placeholder="Enter Questions" formControlName="questionName"> 
                        </div>
                        <button class="Button" (click)="addQuestion()">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="QuestionsEditModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="Category">
                    <a href="javascript:void(0)" class="CloseModal">&times;</a>
                    <h3>Questions</h3>
                    <form [formGroup]="questionsEdit">
                        <div class="form-group">
                            <label>Questions</label>
                            <input type="text" class="form-control" placeholder="Enter Questions" formControlName="questionName"> 
                        </div>
                        <button class="Button" (click)="editQuestion()">Update</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="QuestionsDeleteModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="Decline">
                        <a href="javascript:void(0);" data-dismiss="modal" class="CloseModal">×</a>
                        <h3>Delete</h3>
                        <p>Are you sure you want to delete this Questions ?</p>
                        <h4>
                            <a href="javascript:void(0);" data-dismiss="modal">No</a>
                            <a href="javascript:void(0);" (click)="questionsDelete()">Yes</a>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div> 