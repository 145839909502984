<app-header></app-header>
<app-sidenav></app-sidenav>

<div class="Main-wrapper">
    <div class="Wrapper">

        <div class="CheckinArea">
            <!-- <div class="CheckinTitle">
                <h4>$ Free Health Dollar</h4>
            </div>  -->

            <div class="CheckinDoctor">

                <figure>
                    <img src="assets/images/doctor-1.svg" alt="">
                </figure>

                <h4>Want toTalk to a Physician <br>
                    about it ?</h4>
            </div>

   

            <div class="CommonButon">
                <button class="Button" (click)="onYes()">Yes</button>
                <h4>
                    <a (click)="onNo()">No</a>
                </h4> 
            </div>
        </div> 
    </div>
</div>