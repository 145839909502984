<div class="LoginArea">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/set-password.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <a [routerLink]="['/']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
            <figure><img src="assets/images/set-password.svg" /></figure>
            <h3>Token Expire</h3>
            <p>It seems that your token is expire</p>
        </aside>
    </div>

</div>