<div class="LoginArea LoginReverse">
    <div class="LoginLeft">
        <aside>
            <video controls>
                <source src="movie.mp4" type="video/mp4"> 
                Your browser does not support the video tag.
            </video>
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/VPHGa09cFuE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <a [routerLink]="['/register-medication']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
            <h5 class="Step">Step 6 of 7 </h5> 
            <h1>Click On</h1>
            <form action=""> 

                <div class="ClickBox">
                    <div class="Click">
                        <span class="ClickIcon"><img src="assets/images/Click-1.svg" /> </span>
                        <h4>23 and me</h4>
                    </div>
                    <div class="Click">
                        <span class="ClickIcon"><img src="assets/images/Click-2.svg" /> </span>
                        <h4>Purchase a DNA test</h4>
                    </div>
                    <div class="Click">
                        <span class="ClickIcon"><img src="assets/images/Click-3.svg" /> </span>
                        <h4>Ancestry</h4>
                    </div>
                </div>
            
                <button [routerLink]="['/register-membership']">Next <img src="assets/images/next-arrow.svg" /></button>
            </form>
        </aside>

        <div class="Comming">
            <p>Coming Soon</p>
        </div>

    </div>
</div>

 