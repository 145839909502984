<app-header></app-header>
<app-sidenav></app-sidenav>

<div class="Main-wrapper">
    <div class="Wrapper">

        <div class="BreadcumBox">
            <h5>Rewards</h5>
            <ul>
                <li><a href="javascript:void(0);"><img src="assets/images/home.svg"></a></li>
                <li><a href="javascript:void(0);">Home </a></li>
                <li><a href="javascript:void(0);"> My Rewards</a></li>
            </ul>
        </div> 
        
        <div class="RewardsArea">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#Earn">Earn More</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Progress">Earned Rewards</a>
                </li>
            </ul>
            
            <div class="tab-content">
                <div class="tab-pane active" id="Earn">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-1.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-2.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-3.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-4.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-4.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-2.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-5.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-6.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="tab-pane fade" id="Progress">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-1.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-2.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-3.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-4.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-4.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-2.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-5.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="RewardsBox">
                                <figure>
                                    <img src="assets/images/Rewards-6.svg" alt="">
                                </figure>
                                <h3>Talk to a doctor in Dentistry</h3>
                                <p>Caring for Your Toodler</p>
                                <span>
                                    <strong>640</strong>
                                    Credit
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            
        </div> 
 
    </div>
</div>