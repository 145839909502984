<section>
        <div class="ContactArea">
            <div class="container">
            	<h1>Support</h1>
                <div class="row">

                    <div class="col-sm-4">
                        <div class="ContactBox">
                            <figure><img src="assets/images/Contact-1.svg"></figure>
                            <h3>Talk to us</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur <br>
                                sadipscing elitr.</p>
                            <h4>+9195165***86</h4> 
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="ContactBox">
                            <figure><img src="assets/images/Contact-2.svg"></figure>
                            <h3>Talk to us</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur <br>
                                sadipscing elitr.</p>
                            <h4>support@cordato.com</h4> 
                            <a href="javascript:void(0);">Email Us</a>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="ContactBox">
                            <figure><img src="assets/images/Contact-3.svg"></figure>
                            <h3>Reach Us</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur <br>
                                sadipscing elitr.</p>
                            <h4>H-146, 147, Sector 63 Rd, <br>
                                Noida - 201301</h4> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>