<app-header></app-header>
<app-sidenav></app-sidenav>

<div class="Main-wrapper">
    <div class="Wrapper">

        <div class="BreadcumBox">
            <h5>Contact Us</h5>
            <ul>
                <li><a href="javascript:void(0);"><img src="assets/images/home.svg"></a></li>
                <li><a href="javascript:void(0);">Home </a></li>
                <li><a href="javascript:void(0);">Contact Us</a></li>
            </ul>
        </div>

        <div class="CommonArea">
            <div class="CommonHead">
                <figure>
                    <img src="assets/images/Privacy-3.svg">
                </figure>
                <h5>Contact Support</h5>
            </div>
            <div class="CommonBody">
                <div class="CommonForm">
                    <h4 class="new">If you have any questions or problems please contact support at <a
                            href="javascript:void(0)">support@cordato.com</a></h4>
                    <!-- <div class="row">
                        <div class="col-sm-10">
                            <div class="form-group">
                                <textarea rows="8" class="form-control" placeholder="Write here"></textarea>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="CommonButon">
            <button class="Button" data-toggle="modal" data-target="#TermsModal">Send your query</button>
            <h4><a href="javascript:void(0);">Email Us</a></h4>
        </div> -->
    </div>
</div>

<div class="ModalBox">
    <div class="modal" id="TermsModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="CongratulationBox">
                    <figure style="width: 125px;">
                        <img src="assets/images/Emoji2.svg">
                    </figure>
                    <h3>Thank You !</h3>
                    <p>Thanks for contacting us <br> Our support team will contact you soon.</p>
                    <a href="javascript:void(0);" data-dismiss="modal">ok</a>
                </div>
            </div>
        </div>
    </div>
</div>