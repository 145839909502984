<div class="LoginArea LoginReverse">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/forgot.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <h1>Forgot Password</h1>
            <h6>Please enter your email below to receive your
                password reset instructions.</h6>
            <form action="" [formGroup]="formGroup">
                <div class="form-group">
                    <label for="">Email</label>
                    <input formControlName="email" type="email" class="form-control" placeholder="Enter Your Email">
                </div> 
                <div class="text-danger"
                    *ngIf="(formGroup.get('email')?.hasError('required') && (formGroup.get('email')?.dirty))|| (formInvalid && !this.formGroup.value.email)">
                    *Email is required
                </div>
                <div class="text-danger"
                    *ngIf="(formGroup.get('email')?.hasError('pattern') && (formGroup.get('email')?.dirty))">
                    *Please enter valid email id
                </div>
                <h5>&nbsp;</h5>
                <button (click)="onSendMail()">Send Email</button>
            </form>
        </aside>
    </div>
</div>
