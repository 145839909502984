<div class="LoginArea LoginReverse" *ngIf="step==1">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/Persoanl.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <a [routerLink]="['/register-profile']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
            <h5 class="Step">Step 3 of 7 </h5>
            <h1>Personal Details</h1>
            <h6>Update your personal details to get better <br> the answer from doctor.</h6>

            <form [formGroup]="formGroup1" action="">
                <div class="form-group">
                    <label for="">Address</label>
                    <input (click)="onStep()" readonly formControlName="address" type="text" class="form-control" placeholder="Enter Address">
                    <span class="Icon" (click)="onStep()"><img src="assets/images/location-icon.svg"></span>
                </div>
                <div class="text-danger"
                    *ngIf="(formGroup1.get('address')?.hasError('required') && (formGroup1.get('address')?.dirty))|| (formInvalid1 && !this.formGroup1.value.address)">
                    *Address is required
                </div>
 
                <div class="form-group">
                    <label for="">Phone Number</label>

                    <ngx-intl-tel-input formControlName="phoneNumber" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [selectedCountryISO]="CountryISO[selectedCountry]"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [phoneValidation]="false" [separateDialCode]="true">
                    </ngx-intl-tel-input>
 
                </div>
                <div class="text-danger" *ngIf="phoneInvalid">
                    *Phone Number is required
                </div>

                <div class="form-group">
                    <label for="">Driver License / Identification <a class="ToolBox" href="javascript:void(0);">Why ? <span class="ToolTip">Need driving license for verification Purpose</span> </a></label>
                    <input formControlName="fileSource" (change)="onFileChange($event)" type="file"  class="form-control">
                    <span class="Icon"><img src="assets/images/camera.svg"></span>
                </div>
                <div class="text-danger" *ngIf="filevalidation">
                    *Driver License / Identification is required
                </div>

                <div class="form-group">
                    <label for="">Race</label>
                    <select formControlName="raceId" name="" class="form-control">
                        <option value="">Select</option>
                        <option *ngFor="let item of raceList" value="{{item['_id']}}">{{item['name']}}</option> 
                    </select>
                    <span class="Icon"><img src="assets/images/down-arrow.svg"></span>
                </div>
                <div class="text-danger"
                    *ngIf="(formGroup1.get('raceId')?.hasError('required') && (formGroup1.get('raceId')?.dirty))|| (formInvalid1 && !this.formGroup1.value.raceId)">
                    *Race is required
                </div>

                <button (click)="onSaveStep()">Next <img src="assets/images/next-arrow.svg" /></button>
            </form>
        </aside>
    </div>
</div>

<div class="LoginArea LoginReverse" *ngIf="step==2">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/Location.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight LocationBox">
        <div class="Map">
            <a (click)="onStep()" class="BackButton" style="top: 10px; right: 10px;"><i class="fa fa-long-arrow-left"></i> Back</a>

            <agm-map [latitude]="currentLat" [longitude]="currentLong" [zoom]="zoom" [disableDefaultUI]="false"
                [zoomControl]="false" (mapClick)="mapClicked($event)">
                <agm-marker [latitude]="currentLat" [longitude]="currentLong"></agm-marker>
            </agm-map>
    
            <div class="Search">
                <span><img src="assets/images/Search.svg" /></span>
                <form [formGroup]="formGroup" action="">
                    <input formControlName="searchAddress" id="sourcePlace" (focus)="focusFunction('sourcePlace')" type="text" class="form-control" placeholder="Search Address">
                </form> 
            </div>

            <div class="text-danger"
                *ngIf="(formGroup.get('searchAddress')?.hasError('required') && (formGroup.get('searchAddress')?.dirty))|| (formInvalid && !this.formGroup.value.searchAddress)">
                *Address is required
            </div>
        </div>

        <div class="Form">
            <aside>
                <form [formGroup]="formGroup" action="">
                    <div class="form-group">
                        <label for="">Address</label>
                        <input formControlName="address" type="text" class="form-control" placeholder="Enter Address">
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('address')?.hasError('required') && (formGroup.get('address')?.dirty))|| (formInvalid && !this.formGroup.value.address)">
                        *Address is required
                    </div>
                    <div class="form-group">
                        <label for="">Building/Apartment No.</label>
                        <input formControlName="building" type="text" class="form-control" placeholder="Enter Building No." value="H-146/147">
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('building')?.hasError('required') && (formGroup.get('building')?.dirty))|| (formInvalid && !this.formGroup.value.building)">
                        *Building is required
                    </div>

                </form>
                <button (click)="onSetLoction()">Set Location <img src="assets/images/next-arrow.svg" /></button>
            </aside>
        </div>
    </div>
</div>