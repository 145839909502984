<div class="LoginArea">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/Persoanl.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <a [routerLink]="['/register-diagnosed']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
            <h5 class="Step">Step 5 of 7 </h5>
            <h1>Add Family Members</h1>
            <h6>Please add your family history</h6>

            <form>
            	<div class="FamilyList">
            	<ul>
            		<li *ngFor="let params of parameterList">
            			<div class="FamilyListBox">
	            			<div class="FamilyListLeft">
	            				<p>{{params.relation}} ({{params.name}})</p>
	            			</div>
	            			<div class="FamilyListRigth">
	            				<a  [queryParams]="{ familyId:params._id}" class="Edit" [routerLink]="['/register-family-member-edit']">
		            				<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									  	<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
									</svg>
								</a>

								<a class="Delete" href="javascript:void(0);" data-toggle="modal" (click)="onDeleteFunction(params)" >
									<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
									  	<path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
									</svg>
								</a>
	            			</div>
	            		</div>
            		</li>


            		


            	</ul>
            	<a class="AddMember" [routerLink]="['/register-family-member-add']">
            		+ Add Family Member
            	</a>
            </div>

            	<button (click)="onNextValue()">Next <img src="assets/images/next-arrow.svg" /></button>
            </form>
        </aside>
    </div>
</div>
                  
<div class="ModalBox">
    <div class="modal" id="DeleteModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="Decline">
                        <a href="JavaScript:Void(0);" data-dismiss="modal" class="CloseModal">×</a>
                        <h3>Delete</h3>
                        <p>Are you sure you want to delete this Family Member?</p>
                        <h4>
                            <a href="JavaScript:Void(0);" data-dismiss="modal">No</a>
                            <a href="JavaScript:Void(0);" (click)="onYes()">Yes</a>
                        </h4>
                    </div>
                 </div>
            </div>
        </div>
    </div>    
</div> 