import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-of-service',
  templateUrl: './term-of-service.component.html',
  styleUrls: ['./term-of-service.component.css']
})
export class TermOfServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
