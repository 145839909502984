<div class="LoginArea">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/set-password.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <a [routerLink]="['/']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
          
            <div class="Seccessreset">
                <img src="assets/images/set-password.svg" />
                <h3>Password reset successfully</h3>
                <p>Your password reset successfully</p>
            </div>
        </aside>
    </div>

</div>