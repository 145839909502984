<div class="TosterError" *ngIf="tostarMsg">
    <p><span><i class="fa fa-times"></i></span> Email Id already exist</p>
</div>

<div class="LoginArea LoginReverse">
    <div class="LoginLeft">
        <aside>
            <figure><img src="assets/images/set-password.svg" /></figure>
            <h3>Lorem Ipsum is simply</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
        </aside>
    </div>

    <div class="LoginRight">
        <aside>
            <a [routerLink]="['/login']" class="BackButton"><i class="fa fa-long-arrow-left"></i> Back</a>
            <h5 class="Step">Step 1 of 7 </h5>
            <div [hidden]="OTPScreen" >
                <h1>Set Password</h1>
                <h6>Please enter the email and create new <br>
                    password.</h6>

                <form [formGroup]="formGroup">
                    <div class="form-group">
                        <label>Email</label>
                        <input formControlName="email" type="email" class="form-control" placeholder="Enter Your Email">
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('email')?.hasError('required') && (formGroup.get('email')?.dirty))|| (formInvalid && !this.formGroup.value.email)">
                        *Email is required
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('email')?.hasError('pattern') && (formGroup.get('email')?.dirty))">
                        *Please enter valid email id
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <input (change)="onChangePass()" formControlName="newPassword" #y type="password" class="form-control" placeholder="Enter Password">
                        <span class="Icon Cross" (click)="y.type=y.type=='password'?'text':'password'"><img src="assets/images/eye.png"></span>
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('newPassword')?.hasError('required') && (formGroup.get('newPassword')?.dirty))|| (formInvalid && !this.formGroup.value.newPassword)">
                        *Password is required
                    </div>
                    <div class="text-danger"
                        *ngIf="(!formGroup.get('newPassword')?.hasError('pattern') && formGroup.get('newPassword')?.hasError('minlength') && (formGroup.get('newPassword')?.dirty))">
                        *Password must be of minimum 6 characters length
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('newPassword')?.hasError('maxlength'))">
                        *Password can be of max 16 characters length
                    </div>

                    <div class="form-group">
                        <label>Re-Enter Password</label>
                        <input (change)="onChangePass()" formControlName="conPassword" #x type="password" class="form-control" placeholder="Enter Password">
                        <span class="Icon Cross" (click)="x.type=x.type=='password'?'text':'password'"><img src="assets/images/eye.png"></span>
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('conPassword')?.hasError('required') && (formGroup.get('conPassword')?.dirty))|| (formInvalid && !this.formGroup.value.conPassword)">
                        *Re-Enter Password is required
                    </div>
                    <div class="text-danger" *ngIf="confirmPass">
                        *Password and confirm password must be same
                    </div>
                    <button (click)="onOTPSend()">Next <img src="assets/images/next-arrow.svg" /></button>
                </form>
            </div>

            <div [hidden]="!OTPScreen" >
                <h1>OTP Verification</h1>
                <h6>Please enter the your OTP and Continue</h6>

                <form [formGroup]="OTPForm">

                    <div class="form-group">
                        <label>OTP</label>
                        <input formControlName="otp" type="number" class="form-control" placeholder="xxxxxx">
                    </div>

                    <div class="text-danger" *ngIf="wrongOTP">
                        Given otp is inorrect
                    </div>

                    <h5>
                        <a href="javascript:void(0)" (click)="onOTPSend()">Resend</a>
                    </h5>

                    <!-- <div class="form-group">
                        <label>Email</label>
                        <input formControlName="email" type="email" class="form-control" placeholder="Enter Your Email">
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('email')?.hasError('required') && (formGroup.get('email')?.dirty))|| (formInvalid && !this.formGroup.value.email)">
                        *Email is required
                    </div>
                    <div class="text-danger"
                        *ngIf="(formGroup.get('email')?.hasError('pattern') && (formGroup.get('email')?.dirty))">
                        *Please enter valid email id
                    </div> -->

                    
                    <button (click)="onOTPVerify()">Continue <img src="assets/images/next-arrow.svg" /></button>
                </form>

            </div>
        </aside>
    </div>
</div>